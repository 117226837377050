import React, { useLayoutEffect, useState } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.scss';
import FooterLogo from '../../../../assets/logoFooter.png';
import SloganLogo from '../../../../assets/trout-unlimited.png';
import { ExternalLink, H4, Modal, NamedLink, PrimaryButton } from '../../../../components';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,
    onManageDisableScrolling,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });

  const showSocialMediaLinks = socialMediaLinks?.length > 0;

  // use block builder instead of mapping blocks manually

  const [isModalOpen, setIsModalOpen] = useState(false);

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '7872936',
          formId: '2a63377e-d22b-4c9c-9bde-a4a9653a9c66',
          target: '#hubspot-subscribe-form',
        });
      }
    };
    document.body.appendChild(script);
  }, []);

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <div className={css.footer}>
        <div className={css.desktop}>
          <div className={classNames(css.content, getContentCss(numberOfColumns))}>
            <div className={css.sloganContainer}>
              <div className={css.footerImgContainer}>
                <NamedLink name="LandingPage">
                  <img className={css.footerImg} src={FooterLogo} alt={'RareWaters'} />
                </NamedLink>

                <div className={css.detailsInfo}>
                  <div className={css.sloganDesktop}>
                    <Field data={slogan} className={css.slogan} />
                    <ExternalLink href="https://www.tu.org/">
                      <img className={css.sloganImg} src={SloganLogo} alt={'RareWatersSlogan'} />
                    </ExternalLink>
                  </div>
                </div>
              </div>
            </div>
            <div className={classNames(css.grid, getGridCss(numberOfColumns), css.footerRightMenu)}>
              <BlockBuilder blocks={blocks} options={options} />
            </div>
            <Modal
              id="HubspotSubscribeModal"
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <H4>Subscribe to our newsletter</H4>
              <div id="hubspot-subscribe-form"></div>
            </Modal>
            <PrimaryButton
              className={css.hubspotSubscribeButton}
              onClick={() => setIsModalOpen(true)}
            >
              Subscribe to our Newsletter
            </PrimaryButton>
          </div>
          <div className={css.socialAndCopySection}>
            <div className={css.copyrightSection}>
              <Field data={copyright} className={css.copyright} />
              <div className={css.verticalBar}></div>
              <NamedLink className={css.copyright} name="CustomTermsOfServicePage">
                Terms of Use
              </NamedLink>
              <div className={css.verticalBar}></div>
              <NamedLink className={css.copyright} name="CustomPrivacyPolicyPage">
                Privacy Policy
              </NamedLink>
            </div>

            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} options={options} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={css.mobile}>
        <div className={css.rowUnsetMarginLR}>
          <div className={css.col12}>
            <div className={css.footerImgContainerMobile}>
              <NamedLink name="LandingPage">
                <img className={css.footerImgMobile} src={FooterLogo} alt={'RareWaters'} />
              </NamedLink>
            </div>
          </div>
          <div className={css.col12}>
            <div className={css.sloganMobileWrapper}>
              <Field data={slogan} className={css.sloganMobile} />
            </div>
            <ExternalLink href="https://www.tu.org/">
              <img className={css.sloganImgMobile} src={SloganLogo} alt={'RareWatersSlogan'} />
            </ExternalLink>
          </div>
          <div className={css.col12}>
            <div className={css.hubspotSubscribeWrapperMobile}>
              <PrimaryButton
                className={css.hubspotSubscribeButtonMobile}
                onClick={() => setIsModalOpen(true)}
              >
                Subscribe to our Newsletter
              </PrimaryButton>
            </div>
          </div>
          <div className={css.col12}>
            <div className={css.footerMobileMenuItem}>
              <div className={classNames(css.footerMobileContent, getContentCss(numberOfColumns))}>
                <div
                  className={classNames(css.grid, getGridCss(numberOfColumns), css.footerLeftMenu)}
                >
                  <BlockBuilder blocks={blocks} options={options} />
                </div>
              </div>
            </div>
          </div>
          <div className={css.col12}>
            {showSocialMediaLinks ? (
              <div className={css.socialIconsMobile}>
                <BlockBuilder blocks={linksWithBlockId} options={options} />
              </div>
            ) : null}
          </div>
          <div className={css.col12}>
            <div className={css.copyrightSection}>
              <Field data={copyright} className={css.copyright} />
              <div className={css.verticalBar}></div>
              <NamedLink className={css.copyright} name="CustomTermsOfServicePage">
                Terms of Use
              </NamedLink>
              <div className={css.verticalBar}></div>
              <NamedLink className={css.copyright} name="CustomPrivacyPolicyPage">
                Privacy Policy
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
